import { pgettext } from 'ievv_jsbase/lib/gettext'

import { DEFAULT_SORT } from './sorting'
import { requirePlaceOnProposal } from './place'

let _instance = null

/**
 * A singleton class where all urlpatterns (for routing) are available, as well as utility-functions to create them.
 *
 * All routed urls should be added here, with patterns and makeUrl-functions.
 *
 * Hardcoded parts of urls should be translated using `gettext.pgettext('proposalbin_javascript_v2, url', 'mypage')`.
 */
export default class UrlConfigSingleton {
  constructor() {
    if (!_instance) {
      _instance = this
      this._rootUrl = '/'
    }
    return _instance
  }

  setRootUrl(rootUrl) {
    this._rootUrl = rootUrl
  }

  /** ****** HOME ****** **/
  get homePageUrl() {
    return this._rootUrl
  }

  /** ****** PRIVACY ****** **/
  get privacyUrl() {
    return `${this._rootUrl}${pgettext('proposalbin url', 'personvern')}`
  }

  /** ****** ABOUT ****** **/
  get aboutUrl() {
    return `${this._rootUrl}${pgettext('proposalbin url', 'om')}`
  }

  /** ****** RULES ****** **/
  get rulesUrl() {
    return `${this._rootUrl}${pgettext('proposalbin url', 'regler')}`
  }

  /** ****** PROPOSALS ****** **/
  get _proposalsUrlRoot() {
    return pgettext('proposalbin_javascript_v2, url', 'proposals')
  }

  get proposalsUrl() {
    if (requirePlaceOnProposal) {
      return `${this._rootUrl}${this._proposalsUrlRoot}/:category?/:sort?/:place?/:page?`
    }

    return `${this._rootUrl}${this._proposalsUrlRoot}/:category?/:sort?/:page?`
  }

  makeProposalsUrl({
    category = 'alle',
    sort = DEFAULT_SORT.slug,
    place = 'alle',
    page = 1,
  } = {}) {
    if (requirePlaceOnProposal) {
      return `${this._rootUrl}${this._proposalsUrlRoot}/${category}/${sort}/${place}/${page}`
    }

    return `${this._rootUrl}${this._proposalsUrlRoot}/${category}/${sort}/${page}`
  }

  /** ****** PROPOSAL ****** **/
  get _proposalUrlRoot() {
    return pgettext('proposalbin_javascript_v2, url', 'proposal')
  }

  get proposalDetailUrl() {
    return `${this._rootUrl}${this._proposalUrlRoot}/:proposalId/:proposalSlug?`
  }

  /**
   * utility to make url that matches {@link proposalDetailUrl}
   * @param proposalId id of proposal to generate url for
   * @param proposalSlug (optional) slug for url.
   * @return {string} url using given proposalId (and slug) that matches {@link proposalDetailUrl}
   */
  makeProposalDetailUrl(proposalId, proposalSlug = null) {
    return `${this._rootUrl}${this._proposalUrlRoot}/${proposalId}${
      proposalSlug === null ? '' : `/${proposalSlug}`
    }`
  }
}
