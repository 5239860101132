import SystemConfigurationSingleton from './SystemConfigurationSingleton'
const config = new SystemConfigurationSingleton()

export const requirePlaceOnProposal = window.ievvProposalbinSettings.require_place_on_proposal

export function getPlace({ id, slug }) {
  if (!id && !slug) {
    return null
  }

  const places = config.get('places')
  const match = places.find(
    item => `${item.id}` === `${id}` || item.slug === slug,
  )

  return match || null
}

export function getPlaceSlug(id) {
  const { slug } = getPlace({ id })

  return slug || null
}

