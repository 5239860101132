let _instance = null

export default class SystemConfigurationSingleton {
  constructor() {
    if (!_instance) {
      _instance = this
      this.systemConfigMap = new Map()
    }
    return _instance
  }

  get(configKey, fallback = null) {
    const value = this.systemConfigMap.get(configKey)
    if (value === undefined || value === null) {
      return fallback
    }
    return value
  }

  set(configKey, configValue) {
    this.systemConfigMap.set(configKey, configValue)
  }

  setAllFromObject(configObject) {
    for (const [key, value] of Object.entries(configObject)) {
      this.set(key, value)
    }
  }
}
