import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import AbstractWidget from 'ievv_jsbase/lib/widget/AbstractWidget'

import Routes from '../pages/Routes'
import SystemConfigurationSingleton from '../utils/SystemConfigurationSingleton'
import UrlConfigSingleton from '../utils/UrlConfigSingleton'

export default class ProposalbinV3Widget extends AbstractWidget {
  get systemConfigOverrides () {
    return {}
  }

  setupSystemConfiguration () {
    const systemConfigObject = {
      ...this.config,
      ...this.systemConfigOverrides
    }
    new SystemConfigurationSingleton().setAllFromObject(systemConfigObject)
  }

  constructor (element, widgetInstanceId) {
    super(element, widgetInstanceId)
    this.setupSystemConfiguration()
    const rootUrl = new SystemConfigurationSingleton().get('urlRoot', '/')
    new UrlConfigSingleton().setRootUrl(rootUrl)
    ReactDOM.render(
      <BrowserRouter>
        <Routes />
      </BrowserRouter>,
      this.element
    )
  }

  destroy () {
    ReactDOM.unmountComponentAtNode(this.element)
  }
}
