import SystemConfigurationSingleton from '../utils/SystemConfigurationSingleton'

const useViewModeIsClosed = () => {
  return new SystemConfigurationSingleton().get('viewMode') === 'closed'
}

const useViewModeIsViewOnly = () => {
  return new SystemConfigurationSingleton().get('viewMode') === 'view-only'
}

const useViewModeIsOpen = () => {
  return new SystemConfigurationSingleton().get('viewMode') === 'open'
}

export {
  useViewModeIsClosed,
  useViewModeIsViewOnly,
  useViewModeIsOpen
}
