import 'ievv_jsbase/lib/polyfill/all'
import 'ievv_jsbase/lib/utils/i18nFallbacks'
import WidgetRegistrySingleton from 'ievv_jsbase/lib/widget/WidgetRegistrySingleton'
import LoggerSingleton from 'ievv_jsbase/lib/log/LoggerSingleton'
import LOGLEVEL from 'ievv_jsbase/lib/log/loglevel'
import { GettextBackendRegistry } from 'ievv_jsbase/lib/gettext/backendregistry'

import ProposalbinV3Widget from './widgets/ProposalbinV3Widget'

// Set the default loglevel
const logger = new LoggerSingleton()
// logger.setDefaultLogLevel(LOGLEVEL.INFO)
logger.setDefaultLogLevel(LOGLEVEL.DEBUG)

// Get an instance of the widget registry
const widgetRegistry = new WidgetRegistrySingleton()

// Register the project specific widgets
// widgetRegistry.registerWidgetClass('something', Something);
widgetRegistry.registerWidgetClass('ProposalbinUiV3Widget', ProposalbinV3Widget)

// Setup i18n
new GettextBackendRegistry().activateBackend('django')

// Initalize the widgets
function _onDomReady () {
  // Initialize all widgets in document.body
  widgetRegistry.initializeAllWidgetsWithinElement(document.body)
  logger.getLogger('proposalbinUiV3Setup').debug('initialized widgets.')
}

// import 'moment' and uncomment this line to enable moment:
// window.moment = moment  // Required to load the moment locales

if (document.readyState !== 'loading') {
  _onDomReady()
} else {
  document.addEventListener('DOMContentLoaded', () => {
    _onDomReady()
  })
}

logger.getLogger('proposalbinUiV3Setup').debug('Setup complete.')
