import { pgettext } from 'ievv_jsbase/lib/gettext'

const SORT_OPTIONS = [
  {
    value: 'popular',
    slug: null,
    name: pgettext('proposalbin sort important popular', 'er populære'),
  },
  {
    value: 'recent',
    slug: 'nylig',
    name: pgettext('proposalbin sort recent', 'er lagt til nylig'),
  },
  {
    value: 'important',
    slug: 'viktig',
    name: pgettext('proposalbin sort important', 'er stemt på som Viktig'),
  },
  {
    value: 'innovative',
    slug: 'nytenkende',
    name: pgettext('proposalbin sort innovative', 'er stemt på som Nytenkende'),
  },
  // {
  //   value: 'fun',
  //   slug: 'morsomt',
  //   name: pgettext('proposalbin sort fun', 'er stemt på som Morsomt'),
  // },
]

export default SORT_OPTIONS
export const DEFAULT_SORT = SORT_OPTIONS[0]

export function getSort({ value, slug }) {
  if (!value && !slug) {
    return null
  }

  const match = SORT_OPTIONS.find((item) => item.value === value || item.slug === slug)

  return match || null
}
