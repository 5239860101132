import React, { Suspense, lazy } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { get } from 'lodash'

import UrlConfigSingleton from '../utils/UrlConfigSingleton'
import Loading from '../components/Loading'
import PageTransition from '../components/PageTransition'
import { useViewModeIsClosed } from '../hooks/useViewMode'

// Components used in routing are lazy-loaded, so webpack can code-split our routes, and ensure that initial load-time is reduced.
const Home = lazy(() => import('./Home'))
const Proposal = lazy(() => import('./Proposal'))
const Proposals = lazy(() => import('./Proposals'))
const About = lazy(() => import('./About'))
const Privacy = lazy(() => import('./Privacy'))
const Rules = lazy(() => import('./Rules'))
const Closed = lazy(() => import('./Closed'))

export default function Routes () {
  const isClosed = useViewModeIsClosed()
  const urlConfig = new UrlConfigSingleton()

  return (
    <Router fallback={<Loading />}>
      <Route
        render={({ location }) => {
          const { pathname } = location
          const abortScroll = get(location, 'state.noScroll', false)

          const scrollPos =
            window.pageYOffset ||
            document.body.scrollTop ||
            document.documentElement.scrollTop
          document.body.style.setProperty('--scroll-pos', `-${scrollPos}px`)
          document.body.classList.remove('block-scrolling')

          if (!abortScroll) {
            window.scrollTo(0, 0)
          }

          return (
            <PageTransition location={pathname}>
              <Route
                location={location}
                render={() => (
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      {
                        isClosed
                          ? (
                            <Route
                              exact
                              path={urlConfig.homePageUrl}
                              component={Closed}
                            />
                          )
                          : (
                            <>
                              <Route
                                exact
                                path={urlConfig.homePageUrl}
                                component={Home}
                              />
                              <Route
                                path={urlConfig.proposalsUrl}
                                component={Proposals}
                              />
                              <Route
                                path={urlConfig.proposalDetailUrl}
                                component={Proposal}
                              />
                              <Route path={urlConfig.aboutUrl} component={About} />
                              <Route path={urlConfig.privacyUrl} component={Privacy} />
                              <Route path={urlConfig.rulesUrl} component={Rules} />
                            </>
                          )
                      }
                    </Switch>
                  </Suspense>
                )}
              />
            </PageTransition>
          )
        }}
      />
    </Router>
  )
}
