import React, { useEffect, useState } from 'react'
import T from 'prop-types'
import { gettext } from 'ievv_jsbase/lib/gettext'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('loading')

export default function Loading({ message }) {
  const [show, setShow] = useState(false)
  useEffect(() => {
    const _timeout = setTimeout(() => setShow(true), 300)
    return () => clearTimeout(_timeout)
  }, [])

  if (!show) {
    return null
  }

  return (
    <figure {...bem('')}>
      <svg
        width="220px"
        height="220px"
        viewBox="0 0 220 220"
        {...bem('symbol')}
      >
        <path
          {...bem('fill', '1')}
          d="M137.7,57.8c0,0-33.3-52.5-70.5-31.3c-46.1,26.2-3.8,108.2-3.8,108.2S46.6,73.7,84.7,56.9 C109.4,46,137.7,57.8,137.7,57.8z"
        />
        <path
          {...bem('fill', '2')}
          d="M115.2,23.8c0,0,90.7,18.8,79.6,74.6c-8.1,40.6-53.5,45.4-53.5,45.4s36.3-32.6,13.3-75.8 C139.9,40.5,115.2,23.8,115.2,23.8z"
        />
        <path
          {...bem('fill', '3')}
          d="M184.9,134.8c0,0-48,73-88,42c-31.3-24.1-30.1-71.4-30.1-71.4s21.5,45.6,62.9,47.9 C159.3,155,184.9,134.8,184.9,134.8z"
        />
        <path
          {...bem('fill', '4')}
          d="M90.6,125.4c0,0-39.6-39.4-4-59.6c24.5-14,58.2,2.5,58.2,2.5S105,67.7,93.9,92.6 C86.7,108.9,90.6,125.4,90.6,125.4z"
        />
        <path
          {...bem('fill', '5')}
          d="M108.1,85.3c0,0,43.9-25.4,45.2,8.6c1.1,29.1-24.1,50.1-24.1,50.1s21.7-28.4,7-46.4 C126.9,86.3,108.1,85.3,108.1,85.3z"
        />
        <path
          {...bem('fill', '6')}
          d="M131.4,122.1c0,0-5.1,27.4-24.6,15.5c-16-9.8-8.1-38-8.1-38s0.9,23.6,16.4,28.7 C122.9,130.7,131.4,122.1,131.4,122.1z"
        />
        <path
          {...bem('fill', '7')}
          d="M116.2,121.2c0,0,14.7-1.7,11.8-14.7c-3-13.4-24.8-12.3-24.8-12.3s15,5,16.9,15.9 C121.1,116.6,116.2,121.2,116.2,121.2z"
        />
      </svg>
      <figcaption>{message}</figcaption>
    </figure>
  )
}

Loading.propTypes = {
  message: T.string,
}

Loading.defaultProps = {
  message: gettext('Laster ...'),
}
